import React, { Suspense, memo, lazy } from "react";
import { Switch, withRouter, Route } from "react-router-dom";
import routes from "../../routes";
import Loading from "components/Loading";
import AppRoute from "./AppRoute";
import { ContainerStyled } from "./style";
import BottomMenu from "components/BottomMenu/BottomMenu";
import { isAuthenticated } from "services/Auth";
import { isEmbeded } from "services/config/EmbededService";
import { Redirect } from "react-router-dom";
import AddToHomeScreemServiceWorker from './AddToHomeScreemServiceWorker'
import pathAfterLogin from "services/pathAfterLogin";
import { getToken } from "services/Auth";
import {ProfileService} from "services/index";

import { connect } from 'react-redux'
const NotFound = lazy(() => import("../../components/NotFound"))

const Main = withRouter(props => {
  const {pathname} = props.location;
  const isUserAuthenticated = isAuthenticated();
  pathAfterLogin.setPath(pathname, isUserAuthenticated)
  const hasToChangePassword = () => {
    const token = getToken()
    if(!token) return false
    const parsedToken = ProfileService.parseJWT(getToken() || '')
    const localSuccessChangePassword = localStorage.getItem('SUCCESS_CHANGE_PASSWORD')
    const needChangePassword = parsedToken.changePassword && (!localSuccessChangePassword || parseInt(localSuccessChangePassword) === 0);
    const hasToChangePassword = needChangePassword || !!props.location.search
    localStorage.setItem('HAS_TO_CHANGE_PASSWORD', JSON.stringify({hasToChangePassword}))
    return hasToChangePassword
  }

  const shouldRedirect = () => {
    return [
      "/login",
      "/mfa",
      "/mfa-multiple-questions",
      "/sso",
      "/start",
      "/forgot",
      "/first-access",
      "/first-access/select-method",
      "/",
    ].every(p => p !== pathname) &&
    !pathname.match(/^\/sharedWiki\/wiki\/[0-9]+\/[0-9]+(.+)?/)
  }
  const handleBottomMenu = () => {
    return isEmbeded() ||
    (pathname.match(/^\/sharedWiki\/wiki\/[0-9]+\/[0-9]+(.+)?/) && !isUserAuthenticated) ||
    (hasToChangePassword()) ||
    [
      "/",
      "/chat",
      "/login",
      "/start",
      "/sso",
      "/forgot",
      "/first-access",
      "/first-access/select-method",
      '/chatbot2', 
      "/mfa",
      "/mfa-multiple-questions",
    ].includes(pathname)
  }
  return (
    <ContainerStyled>
      <AddToHomeScreemServiceWorker  />
      <Suspense fallback={<Loading />}>
        { isAuthenticated && handleBottomMenu() ? null : (
          <BottomMenu
            selected={pathname}
            color1={props.teamConfig.color2}
            color2={props.teamConfig.colorDisabled}
            hasBeebot={props.teamConfig.hasBeebot === 'true'}
            teamVisualIdentity={props.teamVisualIdentity}
          />
        )}
        {!isUserAuthenticated &&
        (shouldRedirect()) ? (
          <Redirect to={{ pathname: "/", state: { from: props.location } }}/>
        ) : (
          <Switch>
            {routes.map(route => (
              <AppRoute key={route.path} {...route} />
            ))}
            <Route path="/*" component={NotFound}/>
          </Switch>
        )}
      </Suspense>

    </ContainerStyled>
  )});

const mapStateToProps = (state) => ({
  teamVisualIdentity: state.teamState.visualIdentity,
  teamConfig: state.teamState.config
})

export default memo(connect(mapStateToProps, null)(Main))
