import { lazy } from "react";

const FeedScreen = lazy(() => import("../screen/Feed"));
const FeedOpen = lazy(() => import("../screen/Feed/FeedOpen"));
const WikiScreen = lazy(() => import("../screen/Wiki/WikiScreen"));
const CourseScreen = lazy(() => import("../screen/Course/index"));
const CourseOpen = lazy(() => import("screen/Course/Open/CourseOpen"));
const QuizQuestionOpen = lazy(() => import("../screen/Course/Drop/DropOpen"));
const SplashContainer = lazy(() => import("../screen/SplashScreen"));
const ProfileScreen = lazy(() => import("screen/Profile/ProfileScreen"));
const Profile2Screen = lazy(() => import("screen/Profile/Profile2Screen"));
const PinsScreen = lazy(() => import("screen/Pins/PinsScreen"));
const StatementScreen = lazy(() => import("screen/StatementScreen/StatementScreen"));
const StoreScreen = lazy(() => import("screen/Store/StoreScreen"));
const PostOpen = lazy(() => import("screen/Wiki/PostOpen"));
const SharedWiki = lazy(() => import("screen/Wiki/SharedWiki"));
const ChatBotApp = lazy(() => import("screen/ChatBot/index"));
const PopQuizScreen = lazy(() => import("screen/PopQuiz/index"));
const PopQuizOpen = lazy(() => import("screen/PopQuiz/PopQuizOpen"));
const CommentsScreen = lazy(() => import("../screen/Feed/Comments/index"));
const ResetScreen = lazy(() => import("screen/ResetPassword"))
const EditProfileScreen = lazy(() => import("screen/Profile/EditProfile/index"));
const BlockedPageScreen = lazy(() => import("screen/BlockedPage/BlockedPageScreen"));
const ChangeAvatarScreen = lazy(() => import("screen/Profile/EditProfile/ChangeAvatar"));
const BeeliveScreen = lazy(() => import("../screen/Beelive/index"));
const BeeliveLiveScreen = lazy(() => import("../screen/Beelive/live"));
const EditMFAQuestionsScreen = lazy(() => import("screen/EditMFAQuestions"))
const MultipleQuestionsSetup = lazy(() => import("screen/MultipleQuestionsSetup"))
const CheckCredentialsScreen = lazy(() => import("screen/CheckCredentials"))

const MedalsScreen = lazy(() => import("./../screen/Profile/Medals/MedalsScreen"));
const HoleriteScreen = lazy(() => import("screen/Holerite/HoleriteScreen"))
const NextCourse = lazy(() => import("screen/Course/Open/NextCourse"))

const isPasswordValidated = () => {
  return localStorage.getItem("PASSWORD_VALIDATED") === "true";
};

const reset = [
  {
    path: '/',
    component: ResetScreen,
    exact: false
  },
];

const answerMFA = [
  {
    path: '/',
    component: MultipleQuestionsSetup,
    exact: false
  },
];

const mood = [
  {
    path: '/',
    component: FeedScreen,
    exact: false
  },
];

const homeRoutes = [
  {
    path: "/",
    component: SplashContainer,
    exact: true
  },
  {
    path: "/start",
    component: SplashContainer,
    exact: true
  },
  {
    path: "/home",
    component: FeedScreen,
    exact: true
  },
  {
    path: "/feed/:id",
    component: FeedOpen,
    exact: true
  },
  {
    path: "/wiki",
    component: WikiScreen,
    exact: true
  },
  {
    path: "/wiki/search/:text",
    component: WikiScreen,
    exact: true
  },
  {
    path: "/wiki/search",
    component: WikiScreen,
    exact: true
  },
  {
    path: "/wiki/:id/:text?",
    component: PostOpen,
    exact: true
  },
  {
    path: "/sharedWiki/wiki/:version/:id",
    component: SharedWiki,
    exact: true
  },
  {
    path: "/quiz",
    component: CourseScreen,
    exact: true
  },
  {
    path: "/quiz/next/:courseId/:classId",
    component: NextCourse,
    exact: true
  }, {
    path: "/quiz/:id/:idClass",
    component: CourseOpen,
    exact: true
  },
  {
    path: "/quiz/question/:id",
    component: QuizQuestionOpen,
    exact: true
  },
  {
    path: "/profile",
    component: ProfileScreen,
    exact: true
  },
  {
    path: "/profile2",
    component: Profile2Screen,
    exact: true
  },
  {
    path: "/pins",
    component: PinsScreen,
    exact: true
  },
  {
    path: "/statement",
    component: StatementScreen,
    exact: true
  },
  {
    path: "/store",
    component: StoreScreen,
    exact: true
  },
  {
    path: "/postOpen/:id",
    component: PostOpen,
    exact: true
  },
  {
    path: "/chat",
    component: ChatBotApp,
    exact: true
  },
  {
    path: "/quizpop/:id",
    component: PopQuizOpen,
    exact: true
  },
  {
    path: "/quizpop",
    component: PopQuizScreen,
    exact: true
  },
  {
    path: "/comments/:id/:page",
    component: CommentsScreen,
    exact: true
  },
  {
    path: '/reset',
    component: ResetScreen,
    exact: true
  },
  {
    path: '/profile/edit',
    component: EditProfileScreen,
    exact: true
  },
  {
    path: '/profile/edit/avatar',
    component: ChangeAvatarScreen,
    exact: true
  },
  {
    path: '/medals/:medalId',
    component: MedalsScreen,
    exact: true
  },
  {
    path: '/holerite',
    component: HoleriteScreen,
    exact: true
  },
  {
    path: '/401',
    component: BlockedPageScreen,
    exact: true
  }, {
    path: "/beelive",
    component: BeeliveScreen,
    exact: true
  }, {
    path: "/beelive/live/:hash",
    component: BeeliveLiveScreen,
    exact: true
  }, {
    path: "/mfa/multiple-questions/edit",
    component: isPasswordValidated() ? EditMFAQuestionsScreen : CheckCredentialsScreen,
    exact: true
  }, {
    path: "/mfa/multiple-questions/settings",
    component: MultipleQuestionsSetup,
    exact: true
  },
];

const getLocalStorageItem = (key, defaultValue = '{}') => JSON.parse(localStorage.getItem(key) || defaultValue);

const { hasToChangePassword } = getLocalStorageItem("HAS_TO_CHANGE_PASSWORD");
const { requiredMood, showModalMood } = getLocalStorageItem("USER_MOOD", '{"user": {}}').user;

const determineHomeRoute = () => {
  if (hasToChangePassword) return reset;
  if (showModalMood === 1) return mood;
  return homeRoutes;
};

const home = determineHomeRoute();

export default home;